// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_10159 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;

  &::before {
    position: absolute;
    content: "";
    background: url(/i/design/colour-strap.jpg);
    width: 100%;
    height: 10px;
    left: 0;
    bottom: 0;
  }

  .backstretch {
    img {
      animation: zoom 30s ease forwards infinite;

      @keyframes zoom {
        from {
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          transform: scale(1)
        }

        to {
          -webkit-transform: scale(1.1);
          -moz-transform: scale(1.1);
          transform: scale(1.1)
        }
      }
    }
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 400px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 635px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  // for top of page gradient
  &:after {
    @include before(100%, 635px);
    background: url(/i/design/gradient.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
    transform: scale(-1);
    opacity: 0.3;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 60px;
  font-weight: 600;
  line-height: 1.1;
  padding: 0 15px;

  span {
    display: block;
    font-size: 24px;
    line-height: 1.3;
  }

  @media only screen and (max-width: 600px) {
    font-size: 40px;
  }
}

.mlp {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  @media only screen and (max-width: 990px) {
    display: none;
  }
}