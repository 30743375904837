.body_10159 {



    #diary-sec {
        position: relative;

        .diary-flex {
            position: relative;
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 990px) {
                flex-direction: column-reverse;
                align-items: center;
            }

            .left {
                background: #273A7E;

                @media only screen and (max-width: 1265px) {
                    width: 860px;
                }

                @media only screen and (max-width: 1170px) {
                    width: 700px;
                }

                @media only screen and (max-width: 990px) {
                    max-width: 450px;
                    width: 100%;
                    margin: 30px auto;
                }


                img {
                    width: 800px;
                    height: 100%;
                    object-fit: cover;
                    opacity: 0.4;
                    mix-blend-mode: luminosity;

                    &:hover {
                        filter: brightness(110%);
                    }

                    @media only screen and (max-width: 990px) {
                        margin: 0 auto;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    @media only screen and (max-width: 500px) {
                        // width: 90%;
                    }
                }
            }

            .right {
                position: relative;
                width: 60%;
                // padding: 50px 250px 0 150px;

                padding: 50px 150px 0 40px;

                @media only screen and (max-width: 1595px) {
                    padding: 50px 55px 0 40px;
                }

                @media only screen and (max-width: 1560px) {
                    padding: 50px 80px 0 150px;
                }

                @media only screen and (max-width: 1420px) {
                    padding: 50px 60px 0 60px;
                }

                @media only screen and (max-width: 1308px) {
                    padding: 50px 20px 0 60px;
                }

                @media only screen and (max-width: 1265px) {}

                @media only screen and (max-width: 990px) {
                    width: 90%;
                    padding: 30px 0 0 0;
                }

                .SideList {
                    display: flex;
                    justify-content: center;
                    flex-wrap: wrap;
                    gap: 30px;

                    @media only screen and (max-width: 770px) {
                        flex-direction: column;
                        align-items: center;
                    }

                    li {
                        width: 50%;
                        max-width: 310px;
                        min-width: 250px;
                        height: 190px;
                        flex-shrink: 0;
                        transition: 0.3s;

                        @media only screen and (max-width: 1090px) {
                            width: 42%;
                        }

                        @media only screen and (max-width: 770px) {
                            width: 80%;
                            max-width: none;
                        }

                        &:hover {
                            filter: brightness(110%);
                        }


                        &:nth-of-type(1),
                        &:nth-of-type(4) {
                            background: #273A7D;
                        }

                        &:nth-of-type(2),
                        &:nth-of-type(3) {
                            background: #516AC4;
                        }

                        a {
                            color: #fff;
                            height: 100%;
                            display: block;
                            padding: 40px;

                            .date {
                                font-size: 36px;
                                font-weight: 500;
                                text-transform: uppercase;
                                margin: 0 0 25px 0;
                            }

                            .title span {
                                height: fit-content;
                                font-weight: 300;
                            }
                        }
                    }
                }


                h2 {
                    padding: 0 0 60px 170px;
                    font-size: 60px;
                    color: #273A7D;
                    position: relative;
                    line-height: 60px;
                    text-align: left;

                    @media only screen and (max-width: 1845px) {
                        padding: 0 0 60px 140px;
                    }

                    @media only screen and (max-width: 1595px) {
                        padding: 0px 0 60px 30px;
                    }

                    @media only screen and (max-width: 1160px) {
                        font-size: 45px;
                        line-height: 45px;
                    }

                    @media only screen and (max-width: 990px) {
                        padding: 0 0 25px 0;
                        text-align: center;
                    }

                    span {
                        display: block;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        background: #273A7D;
                        height: 1px;
                        width: 150px;
                        transform: rotate(135deg) translateY(-50%);
                        left: -10px;
                        top: 39%;

                        @media only screen and (max-width: 1595px) {
                            // left: -10px;
                            display: none;
                        }

                        @media only screen and (max-width: 990px) {
                            display: none;
                        }
                    }
                }

                .btn-wrap {
                    position: absolute;
                    transform: rotate(270deg);
                    left: -206px;
                    top: 460px;


                    @media only screen and (max-width: 1170px) {
                        top: 425px;

                    }

                    @media only screen and (max-width: 990px) {
                        transform: rotate(0);
                        left: auto;
                        top: auto;
                        position: relative;
                        margin: 30px auto;

                    }

                    a {
                        background: #273A7D;
                        height: 65px;
                        width: 175px;
                        line-height: 65px;
                        box-shadow: 0px 0px 0px 10px #fff;
                        margin: 15px;
                        color: #fff;
                        font-weight: 300;
                        text-transform: uppercase;

                        &:hover {
                            background: #fff;
                            box-shadow: 0px 0px 0px 10px #fff;
                            color: #273A7D;
                            font-weight: 400;

                            @media only screen and (max-width: 990px) {
                                box-shadow: 0px 0px 0px 7px #273A7D;
                            }
                        }
                    }
                }

            }
        }
    }


    #news-sec {

        .news-flex {
            display: flex;
            justify-content: space-between;

            @media only screen and (max-width: 990px) {
                flex-direction: column;
                align-items: center;
            }

            .left {
                position: relative;
                padding: 50px 200px 50px 250px;

                @media only screen and (max-width: 1390px) {
                    padding: 50px 200px 50px 100px;
                }

                @media only screen and (max-width: 1200px) {
                    padding: 50px 80px 50px 100px;
                }

                @media only screen and (max-width: 990px) {
                    width: auto;
                    padding: 30px 0 0 0;
                }

                @media only screen and (max-width: 790px) {
                    width: 80%;
                    flex-wrap: wrap;
                }

                h2 {
                    padding: 0 0 50px 165px;
                    font-size: 60px;
                    color: #273A7E;
                    position: relative;
                    line-height: 60px;
                    text-align: left;

                    @media only screen and (max-width: 1160px) {
                        font-size: 45px;
                        line-height: 45px;
                    }

                    @media only screen and (max-width: 990px) {
                        padding: 0 0 25px 0;
                        text-align: center;
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        background: #273A7E;
                        height: 1px;
                        width: 150px;
                        transform: rotate(135deg) translateY(-50%);
                        left: 0;
                        top: 35%;

                        @media only screen and (max-width: 990px) {
                            display: none;
                        }
                    }
                }

                .SideList {

                    @media only screen and (max-width: 790px) {
                        display: flex;
                        gap: 20px;
                    }

                    @media only screen and (max-width: 700px) {
                        flex-direction: column;
                        align-items: center;
                    }

                    li {
                        @media only screen and (max-width: 790px) {
                            width: 50%;
                        }

                        @media only screen and (max-width: 700px) {
                            width: 290px;

                        }

                    }
                }

                .news-item-flex {
                    display: flex;
                    background: #273A7E;
                    margin: 15px 0;
                    height: 190px;

                    @media only screen and (max-width: 790px) {
                        flex-direction: column;
                        height: auto;

                    }


                    .image {
                        flex-shrink: 0;
                        height: 190px;
                        width: 310px;

                        @media only screen and (max-width: 990px) {
                            img {
                                object-fit: none;
                            }
                        }

                        @media only screen and (max-width: 790px) {
                            flex-shrink: unset;
                            height: auto;
                            width: 100%;
                        }

                    }

                    .info {
                        width: 360px;
                        padding: 40px;
                        color: #fff;
                        font-weight: 300;

                        @media only screen and (max-width: 790px) {
                            width: 100%;
                        }

                        .date {
                            font-size: 24px;
                            font-weight: 500;
                            text-transform: uppercase;
                            margin: 25px 0 0 0;
                        }

                        .title {
                            span {
                                height: auto;
                            }
                        }

                    }
                }

                .btn-wrap {
                    position: absolute;
                    transform: rotate(90deg);
                    right: -205px;
                    top: 440px;
                    z-index: 99;

                    @media only screen and (max-width: 1200px) {
                        // right: -415px;
                    }

                    @media only screen and (max-width: 990px) {
                        transform: rotate(0);
                        right: auto;
                        top: auto;
                        position: relative;
                        margin: 30px auto;

                    }

                    @media only screen and (max-width: 540px) {
                        margin: 15px;
                    }

                    a {
                        background: #273A7E;
                        height: 65px;
                        width: 175px;
                        line-height: 65px;
                        box-shadow: 0px 0px 0px 10px #fff;
                        margin: 0 15px;
                        color: #fff;
                        font-weight: 300;
                        text-transform: uppercase;

                        @media only screen and (max-width: 540px) {
                            margin: 15px;
                        }

                        &:hover {
                            background: #fff;
                            box-shadow: 0px 0px 0px 10px #fff;
                            color: #273A7E;
                            font-weight: 400;

                            @media only screen and (max-width: 990px) {
                                box-shadow: 0px 0px 0px 7px #273A7E;
                            }
                        }
                    }
                }
            }

            .right {
                background: #fff;

                @media only screen and (max-width: 990px) {
                    max-width: 450px;
                    width: 100%;
                    margin: 30px auto;
                }

                img {

                    width: 1485px;
                    height: 800px;
                    object-fit: cover;
                    opacity: 0.4;
                    mix-blend-mode: luminosity;

                    &:hover {
                        filter: brightness(110%);
                    }

                    @media only screen and (max-width: 990px) {
                        margin: 0 auto;
                        width: 100%;
                        height: auto;
                        object-fit: cover;
                    }

                    @media only screen and (max-width: 500px) {
                        // width: 90%;
                    }
                }

            }
        }
    }

}